import { Article } from '@/Article';
import CLink from '@/CLink';
import { CommonHero } from '@/CommonHero';
import { SecondaryBtn } from '@/buttons';
import { flexCenter } from '@/layout/styles/classes';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';
import { Fragment } from 'react';

const TheArticle = styled(Article)`
    @media (max-width: 280px) {
        display: none;
    }

    > h3 {
        margin: 0 0 32px;
        text-align: center;

        :last-of-type {
            margin-top: 48px;
        }
    }
`;

const IframeBox = styled.div`
    position: relative;

    > p {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
        text-align: center;
        text-transform: none;
    }

    > iframe {
        min-height: 500px;
        width: 100%;
        border: none;

        @media (min-height: 700px) {
            min-height: 600px;
        }

        @media (min-height: 800px) {
            min-height: 700px;
        }
    }
`;

const Btns = styled.div`
    ${flexCenter};
    flex-wrap: wrap;
    column-gap: 64px;
    row-gap: 32px;
    margin-top: 24px;

    @media (min-width: 1024px) {
        margin-top: 40px;
    }
`;

const AppointmentPage = () => {
    return (
        <Fragment>
            <CommonHero
                heading="Book Your Appointment"
                btns={
                    <Btns>
                        <SecondaryBtn
                            as={CLink}
                            to="https://squareup.com/appointments/book/ne29pye4srxztt/5ZWBPF2P8TCV0/start"
                        >
                            West Hollywood
                        </SecondaryBtn>

                        <SecondaryBtn
                            as={CLink}
                            to="https://squareup.com/appointments/book/qshw0r4ni34hec/LYNK4KFWAFJF2/start"
                        >
                            Dana Point
                        </SecondaryBtn>
                    </Btns>
                }
            >
                <StaticImage
                    src="../../assets/images/appointment-hero.jpg"
                    alt="clock"
                    width={846}
                    height={430}
                    loading="eager"
                />
            </CommonHero>
            <TheArticle big>
                <h3>West Hollywood Appointment Booking Form</h3>
                <IframeBox>
                    <p id="west-hollywood-appointment-form-loading-text">
                        Appointment Form Loading...
                    </p>
                    <iframe
                        title="book an appointment with beauty religion at west hollywood "
                        src="https://squareup.com/appointments/buyer/widget/ne29pye4srxztt/5ZWBPF2P8TCV0"
                        loading="lazy"
                        onLoad={() =>
                            document
                                .getElementById('west-hollywood-appointment-form-loading-text')
                                ?.remove()
                        }
                    ></iframe>
                </IframeBox>
                <h3>Dana Point Appointment Booking Form</h3>
                <IframeBox>
                    <p id="dana-point-appointment-form-loading-text">Appointment Form Loading...</p>
                    <iframe
                        title="book an appointment with beauty religion at dana point"
                        src="https://squareup.com/appointments/buyer/widget/qshw0r4ni34hec/LYNK4KFWAFJF2"
                        loading="lazy"
                        onLoad={() =>
                            document
                                .getElementById('dana-point-appointment-form-loading-text')
                                ?.remove()
                        }
                    ></iframe>
                </IframeBox>
            </TheArticle>
        </Fragment>
    );
};

export default AppointmentPage;
